import { useQuery } from "react-query";
import supabase from "../supabase";

export async function fetchByJobIdAndTagNo(jobId, tagNo) {
    const { data, error } = await supabase
        .from("scaffold_tags")
        .select(`*, jobs:job_id(*)`)
        .match({
            job_id: jobId,
            tag_no: tagNo
        });

    if(error) {
        throw new Error(error.message);
    }
    return data;
}

export function useTagsByJobIdAndTagNo(jobId, tagNo) {
    return useQuery("tags", () => fetchByJobIdAndTagNo(jobId, tagNo));
}