import { CheckIcon, XIcon } from "@heroicons/react/outline";
import { useState } from "react";
import moment from "moment";
import { Input, TextArea, Button, ConfirmationDialog } from "../../common";
import { QuotesApi, WeeklyHireApi, JobsApi } from "../../api";
import supabase from "../../api/supabase";

export const EmailSent = ({ quoteId, emailStatus }) => {
  const [isLoading, setIsLoading] = useState(false);
  const updateQuoteMutation = QuotesApi.useUpdateQuote();

  const newStatus = emailStatus === "Ready to send" ? "Sent" : "Ready to send";

  return (
    <ConfirmationDialog
      isDone={updateQuoteMutation.isSuccess}
      icon="info"
      title="Sent Quote"
      body={`Are you sure you wish to mark this quote as ${newStatus} ?`}
      triggerButton={
        <button
          type="button"
          id={quoteId}
          className="ml-3 inline-flex items-center text-sm font-medium focus:outline-none hover:text-green-400"
        >
          {emailStatus === "Ready to send" && < CheckIcon
            className="-ml-0.5 mr-2 h-4 w-4 text-green-400"
            aria-hidden="true"
          />}
          {emailStatus === "Sent" && <XIcon
            className="-ml-0.5 mr-2 h-4 w-4 text-red-400"
            aria-hidden="true"
          />}
          {emailStatus}
        </button >
      }
      confirmButton={
        <Button
          isLoading={isLoading}
          variant="approve"
          onClick={async (e) => {
            setIsLoading(true)
            try {
              const result = await updateQuoteMutation.mutateAsync({
                quote: { emailStatus: newStatus },
                quoteId,
              })
              console.log(result)
            } catch (error) {
              console.log("error")
            }
            setIsLoading(false);
          }}
        >
          Mark as {newStatus}
        </Button >
      }
    />
  );
};
