import { useQuery } from "react-query";
import supabase from "../../supabase";

export async function fetchAdditionalConditions() {
  const { data, error } = await supabase.from("additional_conditions").select("*").order("id", { ascending: true });
  if (error) {
    throw new Error(error.message);
  }
  return data;
}

export function useFetchAdditionalConditions() {
  return useQuery("additionalConditions", () => fetchAdditionalConditions());
}
