import React, { useState, useEffect } from "react";
import styled from "styled-components";
import clsx from "clsx";
import moment from "moment";
import { Link, useLocation } from "react-router-dom";
import { PencilAltIcon, FolderOpenIcon } from "@heroicons/react/solid";

import { FilterMatchMode, FilterOperator } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";

import "primeicons/primeicons.css";
import "primereact/resources/themes/fluent-light/theme.css";
import "primereact/resources/primereact.css";

import { Container } from "../../utils";

import { PageHeading, Badge, Spinner } from "../../common";
import { JobsApi } from "../../api";
import { CreateJob } from "../../components/Jobs";
import supabase from "../../api/supabase";
import { useUpdateJob } from "../../api/Jobs";
import { updateJob } from "../../api/Jobs/updateJob";

export { JobDetails } from "./Details";
export { EditJob } from "./Edit";

export const JobsMain = () => {
  const user = supabase.auth.user();
  const staffData = JobsApi.usefetchAllHandover();

  const jobQuery = JobsApi.useJobs();

  const location = useLocation();
  const [open, setOpen] = useState(false);

  const [filters, setFilters] = useState(null);
  const [globalFilterValue1, setGlobalFilterValue] = useState("");

  const dt = React.useRef(null);
  useEffect(async () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      type: { value: null, matchMode: FilterMatchMode.EQUALS },
      status: { value: "Active", matchMode: FilterMatchMode.EQUALS },
      job_status: { value: "Completed", matchMode: FilterMatchMode.NOT_EQUALS },
      on_hire: { value: null, matchMode: FilterMatchMode.EQUALS },
    });
    setGlobalFilterValue("");
  }, []);

  const formatDate = (value) =>
    value.toLocaleDateString("en-NZ", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });

  const clearFilter = () => {
    initFilters();
    dt.current.reset();
  };

  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
  };

  const onGlobalFilterChange = (e) => {
    const { value } = e.target;
    const _filters = { ...filters };
    _filters.global.value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      type: { value: null, matchMode: FilterMatchMode.EQUALS },
      status: { value: "Active", matchMode: FilterMatchMode.EQUALS },
      job_status: { value: "Complete", matchMode: FilterMatchMode.NOT_EQUALS },
      on_hire: { value: null, matchMode: FilterMatchMode.EQUALS },
    });
    setGlobalFilterValue("");
  };

  const renderHeader = () => (
    <div className="-mb-12 -mt-8">
      <div className="flex items-center">
        <Button
          type="button"
          icon="pi pi-filter-slash"
          label="Clear Filters"
          className="p-button-outlined"
          onClick={clearFilter}
        />
        <span className="p-input-icon-left ml-2">
          {/* <i className="pi pi-search" /> */}
          <InputText
            value={globalFilterValue1}
            onChange={onGlobalFilterChange}
            placeholder="Search Jobs"
          />
        </span>
      </div>
      <div className="mt-4">
        <Button
          type="button"
          icon="pi pi-file"
          label="Export"
          onClick={() => exportCSV(false)}
          className="p-mr-2 p-button-outlined"
          data-pr-tooltip="CSV"
        />
      </div>
    </div>
  );

  const statusFilterTemplate = (options) => (
    <Dropdown
      value={options.value}
      options={["Active", "Inactive"]}
      onChange={(e) => options.filterCallback(e.value, options.index)}
      itemTemplate={(option) => option}
      placeholder="Select a Status"
      className="p-column-filter"
      showClear
      defaultValue="Active"
    />
  );

  const jobStatusDropdowns = ["Pending Handover", "In Progress", "Completed", "Signed-off"];

  const jobOnHireDropdowns = ["Yes", "No"];

  const jobConditionsDropdowns = [
    {
      label: "Equals",
      value: FilterMatchMode.EQUALS,
    },
    {
      label: "Not equals",
      value: FilterMatchMode.NOT_EQUALS,
    },
  ];

  const jobStatusFilterTemplate = (options) => (
    <>
      <Dropdown
        value={filters.job_status.matchMode}
        options={jobConditionsDropdowns}
        onChange={(e) =>
          setFilters({ ...filters, job_status: { ...filters.job_status, matchMode: e.value } })
        }
        // itemTemplate={(option) => option}
        placeholder="Select a Status"
        className="p-column-filter"
        showClear
        defaultValue="In Progress"
      />
      <br />
      <Dropdown
        value={options.value}
        options={jobStatusDropdowns}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        itemTemplate={(option) => option}
        placeholder="Select a Status"
        className="p-column-filter"
        showClear
        defaultValue="In Progress"
      />
    </>
  );

  const jobConditionsFilterTemplate = (options) => (
    <Dropdown
      value={options.value}
      options={jobConditionsDropdowns}
      onChange={(e) => options.filterCallback(e.value, options.index)}
      itemTemplate={(option) => option}
      // placeholder="Select a Status"
      className="p-column-filter"
      showClear
      defaultValue="Equals"
    />
  );

  const jobOnHireFilterTemplate = (options) => (
    <Dropdown
      value={options.value}
      options={jobOnHireDropdowns}
      onChange={(e) => options.filterCallback(e.value, options.index)}
      itemTemplate={(option) => option}
      placeholder="Select"
      className="p-column-filter"
      showClear
    />
  );

  const staffTypeFilterTemplate = (options) => (
    <Dropdown
      value={options.value}
      options={["Employee", "Scaffolder", "Office", "Foreman", "Truck Driver", "Application"]}
      onChange={(e) => options.filterCallback(e.value, options.index)}
      itemTemplate={(option) => option}
      placeholder="Select a Status"
      className="p-column-filter"
      showClear
    />
  );

  const onHireHandle = async (row) => {
    try {
      await updateJob({
        job: { on_hire: "No" },
        jobId: row.id,
      });
      return true;
    } catch (err) {
      console.log("ERROR UPDATING JOB", err);
    }
    return false;
  };

  const showStaff = (job_id) => {
    const data = staffData?.data?.find((e) => e?.job_id === job_id);
    if (!data) return "";

    return data?.staff?.staff_name;
  };

  if (jobQuery.isLoading || !filters) {
    return <Spinner />;
  }

  return (
    <div>
      <PageHeading title="Jobs" createBtn="Create Job" isEditable={false} setOpen={setOpen} />
      <Container>
        <div className="mx-auto mt-8">
          <DataTable
            ref={dt}
            value={jobQuery.data}
            loading={jobQuery.isLoading && staffData.isLoading}
            header={renderHeader()}
            paginator
            paginatorPosition="top|bottom|both"
            showGridlines
            rows={100}
            rowsPerPageOptions={[25, 50, 100]}
            dataKey="id"
            filters={filters}
            filterDisplay="menu"
            // globalFilterMatchMode
            // stripedRows
            responsiveLayout="scroll"
            globalFilterFields={["job_num", "clients.client_name", "site", "job_status"]}
            globalFilter={globalFilterValue1}
            globalFilterMatchMode="contains"
            emptyMessage="No jobs found."
            scrollHeight="600px"
          >
            {/* <Column field="job_id" header="Job" /> */}
            <Column
              header="Job # (Details)"
              field="job_id"
              // filterField="time_on"
              style={{ maxWidth: "8rem", textAlign: "center" }}
              body={(row) => (
                <Link
                  key={`details${row.id}`}
                  to={`jobs/${row.id}/details`}
                  className="flex items-center"
                >
                  <FolderOpenIcon className="h-4 w-4 text-gray-500 mr-2" />
                  <span className="hover:text-gray-800">#{row.job_num}</span>
                </Link>
              )}
            />
            <Column header="Client" field="clients.client_name" />
            <Column header="Site Address" field="site" style={{ minWidth: "10rem" }} />
            <Column
              header="Start Date"
              field="start_date"
              style={{ minWidth: "10rem" }}
              // body={(row) => (row.start_date ? formatDate(row.start_date) : "")}
            />
            <Column header="100% Down Date" field="end_date" style={{ minWidth: "10rem" }} />
            <Column
              field="job_status"
              header="Job Status"
              bodyClassName="p-text-center"
              style={{ width: "10rem" }}
              filter
              filterElement={jobStatusFilterTemplate}
              filterMenuStyle={{ width: "14rem" }}
              showFilterOperator={false}
              showAddButton={false}
              filterMatchMode={filters?.job_status?.matchMode}
              showFilterMatchModes={false}

              // filterMatchMode= "equals"
              // filterMatchModeOptions={[ {
              //     label:"Equals",
              //     value: "equals"
              //   }, {
              //     label: "Not equals",
              //     value: "notEquals"
              //   }]}
            />
            <Column
              field="on_hire"
              header="On Hire"
              bodyClassName="p-text-center"
              style={{ width: "10rem" }}
              filter
              filterElement={jobOnHireFilterTemplate}
              filterMenuStyle={{ width: "14rem" }}
              showFilterOperator={false}
              showAddButton={false}
              filterMatchMode="equals"
              showFilterMatchModes={false}
              body={(row) => {
                const givenDate = moment(row.off_hire_date, "DD/MM/YYYY");
                const isToday = givenDate.isSame(moment(), "day");
                if (isToday && row.on_hire === "Yes") {
                  onHireHandle(row);
                }
                return row.on_hire === "Yes" ? <p>On</p> : <p>Off</p>;
              }}
            />

            <Column
              field="off_hire_date"
              header="Off Hire Date"
              bodyClassName="p-text-center"
              style={{ width: "10rem" }}
            />
            <Column
              field="notes"
              header="Notes"
              bodyClassName="p-text-center"
              style={{ width: "10rem" }}
            />
            <Column
              field="status"
              header="Status"
              bodyClassName="p-text-center"
              style={{ width: "10rem", textAlign: "center" }}
              body={(row) => <Badge type={row.status} text={row.status} />}
              filter
              filterElement={statusFilterTemplate}
              filterMenuStyle={{ width: "14rem" }}
              showFilterOperator={false}
              showAddButton={false}
              filterMatchMode="equals"
              showFilterMatchModes={false}
            />

            <Column
              header="Edit"
              bodyClassName="p-text-center"
              style={{ width: "3rem" }}
              body={(row) => (
                <Link
                  to={{
                    pathname: `jobs/${row.id}/editJob`,
                    state: { background: location, name: "editJob" },
                  }}
                >
                  {user.email === "admin@iscaff.co.nz" ||
                  user.email === "clifton@nss.co.nz" ||
                  user.email === "grant@iscaff.co.nz" ||
                  user.email === "claudia@iscaff.co.nz" ||  
                  user.email === "shaun@nss.co.nz" ||
                  user.email === "accounts@nss.co.nz" ||
                  user.email === "keith@techenabled.nz" ||
                  user.email === "samuel@soluntech.com" ? (
                    <PencilAltIcon className="text-gray-600 h-4 w-4" />
                  ) : (
                    ""
                  )}
                </Link>
              )}
            />
          </DataTable>
        </div>
      </Container>
      <CreateJob open={open} setOpen={setOpen} />
    </div>
  );
};
