import React, { useState, useEffect, useCallback } from "react";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { Button as ButtonReact } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { PencilAltIcon, DuplicateIcon, XIcon } from "@heroicons/react/solid";
import moment from "moment";
import { ConfirmationDialog } from "../../common/Confirmation/Confirmation";
import { PageHeading, Button, Spinner, Button as CustomButton, XeroStatus } from "../../common";
import { CreateEditEdInvoice } from "../../components/Jobs/CreateEditEdInvoice";
import { Container } from "../../utils";
import { EndOfMonth } from "../../components/EDInvoices";
import { JobsApi } from "../../api";
import { useNotificationStore } from "../../store/notifications";

import "primeicons/primeicons.css";
import "primereact/resources/themes/fluent-light/theme.css";
import "primereact/resources/primereact.css";



export const EdInvoicesMain = () => {
    const { addNotification } = useNotificationStore();
    const [invoices, setInvoices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [invoicesSelected, setInvoicesSelected] = useState(null)

    const [job_idToEdit, setJob_idToEdit] = useState(null);
    const [edinvoiceIDToEdit, setEdinvoiceIDToEdit] = useState(null);
    const [openToEdit, setOpenToEdit] = useState(false);

    const createEdInvoiceMutation = JobsApi.useCreateEdInvoice();
    const updateEdInvoiceMutation = JobsApi.useUpdateEdInvoice();
    const deleteEdInvoiceMutation = JobsApi.useDeleteDayWorkTask();

    const [filters, setFilters] = useState(null);
    const [globalFilterValue1, setGlobalFilterValue] = useState("");

    const dt = React.useRef(null);
    useEffect(() => {
        if (loading && invoices.length === 0) {
            JobsApi.fetchAllEdInvoices().then((data) => {
                const Rows = data.filter(row => row.status === "Pending")
                setInvoices(Rows);
                setLoading(false);
            })
        }
    });

    useEffect(() => {
        initFilters();
    }, []);

    const approveInvoices = async () => {
        if (!invoicesSelected) {
            return
        }

        const uniqueJobs = Array.from(new Set(invoicesSelected.map((item) => item.job_id)));
        await uniqueJobs.map(async jobID => {
            const invoices = invoicesSelected.filter(e => e.job_id === jobID);
            await fetch("https://iscaffm.herokuapp.com/api/checkcontact", {
                method: 'POST',
                body: JSON.stringify({
                    client: invoices[0]?.jobs?.billing_address === "No" ? invoices[0]?.jobs?.namesNewClient : invoices[0]?.jobs?.clients?.client_name,
                    email: invoices[0]?.jobs?.billing_address === "No" ? invoices[0]?.jobs?.newContactEmail : invoices[0]?.jobs?.clients?.email,
                    branding: invoices[0]?.jobs?.branding
                }),
                headers: { 'Content-Type': 'application/json' }
            }).then(res => res.json())
                .catch(error => console.error('Error:', error))
                .then(response => console.log('Success:', response));

            fetch("https://iscaffm.herokuapp.com/invoice", {
                method: 'POST', // or 'PUT'
                body: JSON.stringify({
                    type: "edinvoices",
                    data: invoices
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => res.json())
                .catch(error => addNotification({
                    isSuccess: false,
                    heading: "Error!",
                    content: `Network Error With Xero.`,
                }))
                .then(response => {
                    let isSuccess = true;
                    let content = "Success!";
                    if (response.err) {
                        isSuccess = false;
                        content = "Error!";
                    }
                    const heading = response.message;
                    addNotification({
                        isSuccess,
                        heading,
                        content
                    });
                })
        });

        setInvoicesSelected([])
    };

    const headerTemplate = (data) => (
        <td className="" colSpan="6">
            <span className="text-gray-900 font-bold">{`${data?.jobs?.job_num} - ${data?.jobs?.site}`}</span>
        </td>
    );

    const clearFilter = () => {
        initFilters();
    };

    const onGlobalFilterChange = (e) => {

        const { value } = e.target;
        const _filters = { ...filters };
        _filters.global.value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const initFilters = () => {
        setFilters({
            global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        });
        setGlobalFilterValue("");
    };

    const renderHeader = () => (
        <>
            <div className="flex items-center -ml-3">
                <ButtonReact
                    type="button"
                    icon="pi pi-filter-slash"
                    label="Clear Filters"
                    className="p-button-outlined"
                    onClick={clearFilter}
                />
                <span className="p-input-icon-left ml-2">
                    <InputText
                        value={globalFilterValue1}
                        onChange={onGlobalFilterChange}
                        placeholder="Search Invoices"
                    />
                </span>
            </div>
        </>
    );



    if (loading) {
        return (
            <div className="w-full h-48 flex justify-center items-center">
                <Spinner size="lg" />
            </div>
        )
    }

    return (
        <div>
            <Container>
                <PageHeading title="ED Invoices" isEditable={false} />

                <div className="mx-auto mt-8">
                    <Button label="Success" icon="submit" onClick={() => approveInvoices()} className="p-button-success">
                        Approve ED Invoices
                    </Button>

                    <br />

                    <XeroStatus />

                    <DataTable
                        scrollable={!openToEdit}
                        ref={dt}
                        value={invoices}
                        dataKey="id"
                        selectionMode="checkbox"
                        groupRowsBy="job_id"
                        rowGroupMode="subheader"
                        header={renderHeader()}
                        responsiveLayout="scroll"
                        scrollHeight="600px"
                        emptyMessage="No weekly hire invoices found."
                        paginator
                        paginatorPosition="top|bottom|both"
                        showGridlines
                        filters={filters}
                        rows={100}
                        rowsPerPageOptions={[25, 50, 100]}
                        rowGroupHeaderTemplate={headerTemplate}
                        selection={invoicesSelected}
                        onSelectionChange={e => setInvoicesSelected(e.value)}
                    >
                        <Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
                        <Column header="jobs.site" field="jobs.site" hidden />
                        <Column header="jobs.job_num" field="jobs.job_num" hidden />
                        <Column
                            header="Type"
                            headerStyle={{ textAlign: "center" }}
                            field="type"
                            bodyStyle={{ textAlign: "center" }}
                        />
                        <Column
                            header="Description"
                            headerStyle={{ textAlign: "center" }}
                            field="description"
                            bodyStyle={{ textAlign: "center" }}
                        />
                        <Column
                            header="Erect cost"
                            headerStyle={{ textAlign: "center" }}
                            field="erect"
                            body={(row) => `$${row.erect.toFixed(2)}`}
                            bodyStyle={{ textAlign: "center" }}
                        />
                        <Column
                            header="Dismantle cost"
                            headerStyle={{ textAlign: "center" }}
                            field="dismantle"
                            body={(row) => `$${row.dismantle.toFixed(2)}`}
                            bodyStyle={{ textAlign: "center" }}
                        />
                        <Column
                            header="ED Total"
                            headerStyle={{ textAlign: "center" }}
                            field="ed_total"
                            body={(row) => `$${row.ed_total.toFixed(2)}`}
                            bodyStyle={{ textAlign: "center" }}
                        />
                        <Column
                            header="Invoice"
                            headerStyle={{ textAlign: "center" }}
                            field="invoiced"
                            body={(row) => `$${row.invoiced.toFixed(2)}`}
                            bodyStyle={{ textAlign: "center" }}
                        />
                        <Column
                            header="% Complete"
                            headerStyle={{ textAlign: "center" }}
                            field="complete_percent"
                            body={(row) => `${row.complete_percent}%`}
                            bodyStyle={{ textAlign: "center" }}
                        />
                        <Column
                            header="PO Number"
                            headerStyle={{ textAlign: "center" }}
                            field="PO_Number"
                            bodyStyle={{ textAlign: "center" }}
                        />
                        <Column
                            header="Quote"
                            headerStyle={{ textAlign: "center" }}
                            field="Quote_Number"
                            bodyStyle={{ textAlign: "center" }}
                        />
                        <Column
                            header="Duplicate"
                            headerStyle={{ textAlign: "center" }}
                            body={(row) => (
                                <ConfirmationDialog
                                    isDone={createEdInvoiceMutation?.isSuccess}
                                    icon="info"
                                    title="Duplicate ED Invoice"
                                    body="Duplicating this ED invoice will create a copy of this record."
                                    triggerButton={
                                        <button type="button">
                                            <DuplicateIcon className="h-4 w-4 text-gray-600" />
                                        </button>
                                    }
                                    confirmButton={
                                        <Button
                                            isLoading={createEdInvoiceMutation?.isLoading}
                                            variant="primary"
                                            onClick={async (e) => {
                                                e.preventDefault();
                                                delete row.id
                                                row.last_invoice = row.invoiced
                                                try {
                                                    await createEdInvoiceMutation.mutateAsync(row);
                                                    createEdInvoiceMutation.isSuccess = true;
                                                } catch (err) {
                                                    console.log("ERROR DUPLICATING INVOICE", err);
                                                }
                                            }}
                                        >
                                            Duplicate Ed Invoice
                                        </Button>
                                    }
                                />
                            )}
                            bodyStyle={{ textAlign: "center" }}
                        />
                        <Column
                            header="Edit"
                            headerStyle={{ textAlign: "center" }}
                            body={(row) => (
                                <button type="button" onClick={() => {
                                    setEdinvoiceIDToEdit(row.id)
                                    setJob_idToEdit(row.job_id)
                                    setOpenToEdit(true)
                                }}>
                                    <PencilAltIcon className="h-4 w-4 text-gray-600" />
                                </button>
                            )}
                            bodyStyle={{ textAlign: "center" }}
                        />
                        <Column
                            header="Delete"
                            headerStyle={{ textAlign: "center" }}
                            body={(row) => (
                                <ConfirmationDialog
                                    isDone={deleteEdInvoiceMutation.isSuccess}
                                    icon="XIcon"
                                    title="Delete ED Invoice"
                                    body="Are you sure you want to delete this item?"
                                    triggerButton={
                                        <button type="button">
                                            <XIcon className="h-4 w-4 text-gray-600" />
                                        </button>
                                    }
                                    confirmButton={
                                        <CustomButton
                                            isLoading={deleteEdInvoiceMutation?.isLoading}
                                            variant="primary"
                                            onClick={async (e) => {
                                                e.preventDefault();
                                                try {
                                                    await deleteEdInvoiceMutation.mutateAsync(row.id);
                                                } catch (err) {
                                                    console.log("ERROR Deleting INVOICE", err);
                                                }
                                                // console.log("duplicatedInvoice", duplicatedInvoice);
                                            }}
                                        >
                                            Delete Invoice
                                        </CustomButton>
                                    }
                                />
                            )}
                            bodyStyle={{ textAlign: "center" }}
                        />
                        <Column
                            header="Last Time Updated"
                            headerStyle={{ textAlign: "center" }}
                            field="last_time_updated"
                            body={(row) => (
                                moment(row.last_time_updated).format("MMMM Do YYYY")
                            )}
                            bodyStyle={{ textAlign: "center" }}
                        />
                    </DataTable>
                </div>
            </Container>
            {
                openToEdit ?
                    < CreateEditEdInvoice job_id={job_idToEdit} edinvoiceID={edinvoiceIDToEdit} open={openToEdit} setOpen={setOpenToEdit} />
                    : <br />
            }
        </div >
    )
}