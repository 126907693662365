import { useMutation } from "react-query";
import supabase from "../../supabase";

export async function updateAdditionalConditions(additionalConditions) {
  const { data, error } = await supabase.from("quote_additional_conditions").upsert(additionalConditions);

  if (error) {
    throw new Error(error.message);
  }

  return data;
}

export const useUpdateAdditionalConditions = () =>
  useMutation((additionalConditions) => updateAdditionalConditions(additionalConditions), {
    onSuccess: (data) => data,
    onError: (error) => error,
    mutationFn: updateAdditionalConditions,
  });
