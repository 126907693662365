import { useQuery } from "react-query";
import supabase from "../supabase";

async function fetchScaffoldInspectionFilesByTagIdArray(tagIdArray) {
    let appFiles = [];
    // eslint-disable-next-line arrow-body-style
    const result =  await Promise.all(tagIdArray.map(tagId => { 
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject ) => {
        const {data, error} = await supabase
            .from("app_entries")
            .select("*")
            .eq("tag_id", Number(tagId))
            .eq("file_type", "Scaffold Inspection")
            .order("id", { ascending: true});
        if(error) {        
           throw new Error(error.message);
        }  
        resolve(data);
      })
    }))


    if(result .length > 0 ) {            
              result.forEach(element => {
                  appFiles = appFiles.concat(element);
              });         
    }
    return appFiles;
}


  export function useFetchScaffoldInspectionFilesByTagIdArray(tagIdArray) {
    return useQuery("scaffold-inpsection", () => fetchScaffoldInspectionFilesByTagIdArray(tagIdArray));
}