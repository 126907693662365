import { useQuery } from "react-query";
import supabase from "../supabase";



async function fetchScaffoldInspectionByJobId(jobId) {
    const { data, error } = await supabase
        .from("app_entries")
        .select("*")
        .match({"job_id": jobId,
                "file_type": "Scaffold Inspection" })

    if(error) {
        throw new Error(error.message);
    }
    return data;
}


export function useFetchScaffoldInspectionByJobId(jobId) {
    return useQuery("scaffold-inpsection", () => fetchScaffoldInspectionByJobId(jobId));
}