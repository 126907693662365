import { useState } from "react";
import moment from "moment";
import { DateSelect, Button, ConfirmationDialog, Spinner } from "../../common";
import { WeeklyHireApi } from "../../api";
import { useNotificationStore } from "../../store/notifications";

export const EndOfMonth = ({ invoicesSelected, setStandardCompleteDate }) => {
    const { addNotification } = useNotificationStore();
    const [isLoading, setIsLoading] = useState(false);
    const [isDone, setIsDone] = useState(false);
    const [date, setDate] = useState(moment().format("DD/MM/YYYY"));

    const endOfMouth = async () => {
        if (!invoicesSelected) {
            return
        }

        setTimeout(async () => {
            const uniqueJobs = Array.from(new Set(invoicesSelected.map((item) => item.job_id)));

            await uniqueJobs.map(async jobID => {
                const invoices = invoicesSelected.filter(e => e.job_id === jobID);
                await fetch("https://iscaffm.herokuapp.com/api/checkcontact", {
                    method: 'POST',
                    body: JSON.stringify({
                        client: invoices[0]?.jobs?.billing_address === "No" ? invoices[0]?.jobs?.namesNewClient : invoices[0]?.jobs?.clients?.client_name,
                        email: invoices[0]?.jobs?.billing_address === "No" ? invoices[0]?.jobs?.newContactEmail : invoices[0]?.jobs?.clients?.email,
                        branding: invoices[0]?.jobs?.branding
                    }),
                    headers: { 'Content-Type': 'application/json' }
                }).then(res => res.json())
                    .catch(error => console.error('Error:', error))
                    .then(response => console.log('Success:', response));

                // send items to xero to create new invoce
                fetch("https://iscaffm.herokuapp.com/invoice", {
                    method: 'POST', // or 'PUT'
                    body: JSON.stringify({
                        type: "weekly_hire_invoices",
                        endMouth: true,
                        data: invoices,
                        date
                    }),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then(res => res.json())
                    .catch(error => addNotification({
                        isSuccess: false,
                        heading: "Error!",
                        content: `Network Error With Xero.`,
                    }))
                    .then(response => {
                        let isSuccess = true;
                        let content = "Success!";
                        if (response.err) {
                            isSuccess = false;
                            content = "Error!";
                        }
                        const heading = response.message;
                        addNotification({
                            isSuccess,
                            heading,
                            content
                        });

                    })
            });

            setTimeout(() => {
                setStandardCompleteDate(moment().format("DD/MM/YYYY"));
                setIsLoading(false);
                setIsDone(true);
            }, uniqueJobs.length * 3000);
        }, 1000);
    };

    const handleInputChange = (id, val) => {
        setDate(moment(val).format("DD/MM/YYYY"));
        setStandardCompleteDate(moment(val).format("DD/MM/YYYY"));
    }

    return (
        <ConfirmationDialog
            isDone={isDone}
            icon="info"
            title="End Of Month"
            body="This action will create a new weekly hire invoices. Select A Date for the process"
            triggerButton={
                <Button label="End Of The Month"
                    style={{ backgroundColor: "#0078d4", borderRadius: "4px" }}
                    icon="submit"
                    className="p-button-raised p-3 flex align-items-center justify-content-center"
                >
                    End Of The Month
                </Button>
            }
            confirmButton={
                isLoading ? <Spinner size="md" /> : <Button
                    isLoading={isLoading}
                    variant="approve"
                    onClick={async (e) => {
                        setIsLoading(true);
                        endOfMouth();
                    }}
                >
                    End Of The Month
                </Button >
            }
        >
            <div className="flex">

                <DateSelect
                    title="Date End Of Month"
                    id="date"
                    value={date}
                    onChange={handleInputChange}
                />

                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
            </div>
        </ConfirmationDialog >
    );
};